<script>
const APP = {
  // name: '早闻天下事极速版',
  // src: require('../assets/zwtxsjsb.webp'),
  // url: "http://source.jijia-co.com/nav/20230331/lockimage/20230331/appWorldStoryLight_channelOPPO_abiArm32_1.0.0.b_10000002_release.apk"

  // name: '新闻快报',
  // src: require('../assets/xwkb.png')

  // name: '早闻天下',
  // src: require('../assets/zwtx.webp')

  // name: '今日焦点',
  // src: require('../assets/jrjd.webp')

  name: '天天听音乐',
  src: require('../assets/tttyy.webp'),
  url: "http://source.jijia-co.com/nav/20230608/lockimage/20230608/JJ_LookWorldShortVideo_channelJJWebsite_abiAll_1.2.4.a_10204001_release.apk"

  // name: '今日快闻',
  // src: require('../assets/jrkw.jpg'),
  // url: "http://source.jijia-co.com/nav/20230816/lockimage/20230816/appTodayNews_channelOPPO_abiAll_1.0.0.b_10000002_release.apk"
};

export default {
  APP: APP
}
</script>