<template>
  <div id="app">
    <PageContent></PageContent>
    <PageFooter></PageFooter>
  </div>
</template>

<script>
import PageContent from './components/PageContent'
import PageFooter from './components/PageFooter'

export default {
  name: 'App',
  components: {
    PageContent,
    PageFooter
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: white;
}
</style>
