<template>
  <div>
    <footer>
      <item>
        北京技加科技有限公司
      </item>
      <item>
        北京市海淀区上地六街28号2号楼志远大厦3层303室
      </item>
    </footer>
    <footer>
      <item>
        <a target="_blank" href="https://beian.miit.gov.cn">
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京ICP备19023457号-1</p>
        </a>
      </item>
      <item>
        <a target="_blank" href="http://d.jijia-co.com/nav/pages/official/cert/jijia/icp.png">
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京ICP证 京B2-20232639</p>
        </a>
      </item>
      <item>
        <a target="_blank" href="http://d.jijia-co.com/nav/pages/official/cert/jijia/yyzz.png">
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">营业执照</p>
        </a>
      </item>
<!--      <item>-->
<!--        <a target="_blank" href="http://d.jijia-co.com/nav/pages/official/cert/jijia/www.png">-->
<!--          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">网络文化经营许可证：京网文〔2024〕0432-024号</p>-->
<!--        </a>-->
<!--      </item>-->
      <item>
        <a target="_blank" href="http://d.jijia-co.com/nav/pages/official/cert/jijia/cbw.png">
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">出版物经营许可证</p>
        </a>
      </item>
      <item>
        <a target="_blank" href="http://d.jijia-co.com/nav/pages/official/cert/jijia/gdxkz.png">
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">广播电视节目制作经营许可证</p>
        </a>
      </item>
      <item>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802042918" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
          <img src="../assets/gongan.png" style="float:left;"/>
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备11010802042918号</p>
        </a>
      </item>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {}
}
</script>
<style>
footer {
  width: 100%;
  font-size: 12px;
  display: flex;
  justify-content: center;
}

item {
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
  color: #939393;
}
</style>