<template>
  <div class="content">
    <img :src="imageSrc" :style="{height: imgHeight + 'px'}" @click="imgClick(appUrl)">
  </div>
</template>

<script>
import Constant from "./PageConstant"

export default {
  data() {
    return {
      imageSrc: Constant.APP.src,
      appUrl: Constant.APP.url,
      imgHeight: 0,
    }
  },
  created() {
    this.imgHeight = document.documentElement.clientHeight - 105
  },
  mounted() {
    window.onresize = () => {
      this.imgHeight = document.documentElement.clientHeight- 105
    }
  },
  methods: {
    imgClick(url) {
      if (url != undefined) {
        window.open(url);
      }
    }
  }
}
</script>